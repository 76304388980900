.contact_us_main_div {
  overflow: hidden;
}
.policy_us_uper_part {
  display: flex;
  background-image: url(../../Assest/contect_us_naverbar_pic.png);
  flex-direction: row;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.policy_us_text {
  width: 100vw;
  margin-top: 6%;
  margin-bottom: 6%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 23px;
  color: white;
  font-weight: 600;
}

.privacy__heading {
  font-size: 30px;
  margin: 2em 0em;
  font-weight: 800;
}

.list__heading {
  font-size: 22px;
  font-weight: 600;
  margin: 1em 0em 0.5em 0em;
}

.list__desc {
  font-size: 16px;
  text-align: justify;
  margin-bottom: 0.5em;
}

.policy__wrapper {
  padding: 2em 2em;
  /* width: 100%; */
  /* width: 90vw; */
}

@media screen and (max-width: 850px) {
  .policy_us_text {
    margin-top: 22%;
  }
}
