.Blog_uper_part {
  display: flex;
  background-image: url(../../Assest/contect_us_naverbar_pic.png);
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  overflow: hidden;
}
.welcome_part {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  flex-direction: column;
  align-items: center;
}
