.shia_meet_main {
  background-image: url(../../Assest/bg.png);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-position: bottom; */
  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
  /* font-family: Poppins; */
}
/* .shia_meet_main ::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url(../../Assest/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
} */
.shiameet_catagari {
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  width: 30vw;
  justify-content: space-between;
}

.Shiameet_point_catagari1:hover {
  padding-bottom: 1%;
  color: #58b69c;
  border-bottom: 3px solid #58b69c;
}
.Shiameet_point_catagari2:hover {
  padding-bottom: 1%;
  color: #58b69c;
  border-bottom: 3px solid #58b69c;
}
.Shiameet_point_catagari1 {
  padding-bottom: 1%;
  color: #7e7e7e;
  font-size: 20.883px;
  font-weight: 800;
  border-bottom: 3px solid white;
  cursor: pointer;
  transition: color 0.3s ease;
}
.Shiameet_point_catagari2 {
  padding-bottom: 1%;
  color: #7e7e7e;
  font-size: 20.883px;
  font-weight: 800;
  border-bottom: 3px solid white;
  cursor: pointer;
  transition: color 0.3s ease;
}
.Shiameet_point_catagari1.active {
  color: #58b69c; /* Active color for ShiaMeets */
  border-bottom: 3px solid #58b69c;
}

.Shiameet_point_catagari2.active {
  color: #58b69c; /* Active color for ShiaGreets */
  border-bottom: 3px solid #58b69c;
}
.shia_image_block,
.shia_image_block__greet {
  display: flex;
  width: 78vw;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4%;
  margin-top: 1%;
}
.prnt_img {
  width: 38vw !important;
}
.shia_image_block_image {
  width: 30vw;
}
.shia_image_block_image img {
  width: 70%;
}
.shia_image_block_text {
  width: 32vw;
}
.shia_image_block_text_1 {
  color: #1e1e1e;
  font-size: 30.576px;
  font-style: normal;
  font-weight: 700;
  line-height: 124.5%;
  margin-bottom: 2%;
  /* width: 16vw; */
}
.prnt_rep {
  width: 16vw;
}
.shia_image_block_text_2 {
  color: rgba(0, 0, 0, 0.8);
  /* text-align: justify; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.shia_image_block_text_1_color {
  color: #357a67;
}
.Parent_box {
  margin-top: 7%;
}
@media screen and (max-width: 1100px) {
  .shiameet_catagari {
    width: 55vw;
    margin-top: 8%;
    margin-bottom: 12%;
  }
  .prnt_rep {
    width: 25vw;
  }
}
@media screen and (max-width: 700px) {
  .shiameet_catagari {
    width: 75vw;
    margin-top: 8%;
    margin-bottom: 12%;
  }
  .shia_image_block {
    flex-direction: column-reverse;
    margin-bottom: 15%;
  }
  .shia_image_block__greet {
    flex-direction: column;
    margin-bottom: 15%;
  }
  .shia_image_block_text {
    width: 80vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
  }
  .shia_image_block_image {
    width: 40vw;
  }
  .prnt_img {
    width: 40vw !important;
  }
  .shia_image_block_image img {
    width: 95%;
  }
  .shia_image_block_reverse {
    flex-direction: column;
  }
  .shia_image_block_text_1 {
    width: auto;
    font-size: 20px;
    font-weight: 800;
  }
  .prnt_rep {
    width: auto;
  }
  .shia_meet_main {
    background-image: none;
  }
  .shia_image_block_text_2 {
    margin-top: 6%;
    margin-bottom: 6%;
  }
}
@media screen and (max-width: 500px) {
  .prnt_img {
    width: 100% !important;
  }
}
@media screen and (max-width: 285px) {
  .Shiameet_point_catagari1 {
    font-size: 15.883px;
  }
  .Shiameet_point_catagari2 {
    font-size: 15.883px;
  }
}
