.contact_us_main_div {
  overflow: hidden;
}
.contect_us_uper_part {
  display: flex;
  background-image: url(../../Assest/contect_us_naverbar_pic.png);
  flex-direction: row;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
}
.Contect_us_text {
  width: 100vw;
  margin-top: 6%;
  margin-bottom: 6%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 23px;
  color: white;
  font-weight: 600;
}
.contect_us_bottom_part1 {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  width: 100vw;
}
.contect_us_bottom_part2 {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  justify-content: space-between;
  width: 85vw;
}
.contect_us_bottom_part_detail1 {
  padding: 3%;
  padding-left: 5%;
  width: 30vw;
  background-image: url(../../Assest/Contect_us_detail_pic.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  color: white;
  margin-right: 6%;
}
.contect_us_bottom_part_detail2 {
  width: 45vw;
  padding-top: 3%;
  padding-right: 3%;
  padding-left: 3%;
}
.contect_information_text {
  color: #fff;
  /* font-family: Plus Jakarta Sans; */
  font-size: 20.563px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contect_information_text_detail {
  color: #fff;
  /* font-family: Plus Jakarta Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 3%;
  margin-bottom: 7%;
}
.emai_and_call_contact {
  display: flex;
  margin-bottom: 5%;
  align-items: center;
}
.emai_and_call_contact_detail {
  margin-left: 3%;
}
.svg_contact {
  display: flex;
  align-items: center;
}
.p_contact {
  margin-top: -0.01%;
  margin-right: 2.5%;
}
.get_in_touch {
  color: rgb(47, 148, 121);
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 4%;
}
.you_name_and_email_contact {
  display: flex;
}
.you_name_contact_email {
  width: 15vw;
  padding: 4%;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
}
.you_name_contact_email:focus {
  outline: none;
  border: 1px solid #f1f1f1;
}
.you_name_contact {
  margin-right: 13%;
}
.you_name_subject {
  margin-top: 4%;
  width: 35.6vw;
  height: 7vh;
  border: 1px solid #f1f1f1;
  border-radius: 7px;
  padding-left: 2%;
  outline: none;
}
.you_name_subject:focus {
  outline: none;
  border: 1px solid #f1f1f1;
}
.submit_button {
  margin-top: 4%;
  background-color: rgb(47, 148, 121);
  padding: 0.5em 2em;
  border-radius: 15px;
  border: none;
  color: white;
  cursor: pointer;
}
.App {
}
.Textarea {
  margin-top: 4%;
  width: 35.9vw;
  height: 18vh;
  border: 1px solid #f1f1f1;
  padding-left: 2%;
  resize: none;
  border-radius: 7px;
}
.Textarea:focus {
  outline: none;
  border: 1px solid #f1f1f1;
}
@media screen and (max-width: 1100px) {
  .Textarea {
    height: 27vh;
  }
}
@media screen and (max-width: 950px) {
  .Contect_us_text {
    margin-top: 12%;
  }
  .Textarea {
    height: 33vh;
  }
}
@media screen and (max-width: 850px) {
  .Contect_us_text {
    margin-top: 22%;
  }
  .contect_us_bottom_part2 {
    flex-direction: column-reverse;
  }
  .contect_us_bottom_part_detail1 {
    width: auto;
    /* margin-left: 2.4%; */
    padding: 1em;
  }
  .contect_us_bottom_part_detail2 {
    width: auto;
    margin-bottom: 4%;
  }
  .you_name_and_email_contact {
    display: flex;
    flex-direction: column;
  }
  .you_name_contact_email {
    margin-top: 5%;
    width: 70vw;
  }
  .you_name_subject {
    width: 74vw;
    height: 5vh;
  }
  .Textarea {
    width: 74vw;
    height: 18vh;
  }
}
