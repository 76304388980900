.customer__detail__popup {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}
.customer__detail__popup__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  background-color: rgba(31, 31, 31, 0.9);
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
}
.customer__detail__popup__overlay__card {
  height: fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 2em);
  max-width: 500px;
  padding: 2em;
  background-color: white;
  border-radius: 8px;
  position: relative;
  background-image: url("./Assest/md-bg.png");

  background-repeat: no-repeat;
  background-size: 350px 350px;
  background-position: bottom right;
}
.customer__detail__popup__overlay__card__close {
  width: 100%;
  /* height: 30px; */
  /* position: absolute; */
  /* top: -1em;
  right: -1em; */
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* padding: 0px;
  margin: 0px; */
  /* border-radius: 50px; */
  /* border: 1px solid transparent; */
  border: none;
  background-color: transparent;
}
.customer__detail__popup__overlay__card__close svg {
  color: black;
}
.customer__detail__popup__overlay__card__heading {
  font-size: 1.25em;
  font-weight: 800;
  color: black;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.bottom__text {
  text-align: center;
  font-size: 16px;
}

.bottom__text span {
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  /* .customer__detail__popup {
    height: 100%;
    min-height: 100%;
  } */
  .customer__detail__popup__overlay {
    padding: 0;
  }
}
