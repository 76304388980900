/* .navbar_main{
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center; 
} */
.scrolledNavbar {
  background-color: #38a084;
}
.navbar_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 84vw;
  z-index: 1000;
  padding: 25px 8%;
}
.Navbar_menu {
  /* width: 16vw; */
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
}
.Contact_us {
  display: flex;
  background-color: black;
  padding: 0.5em 2em;
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}
.Navbarlogo {
  width: 10vw;
  cursor: pointer;
}
.Navbarlogo img {
  width: 100%;
}
.Blog {
  cursor: pointer;
  margin-right: 2em;
}

/* .navbar_main.scrolled {
    background-color: #38a084;
} */
@media screen and (max-width: 1100px) {
  .Navbar_menu {
    /* width: 30vw; */
  }
}
@media screen and (max-width: 700px) {
  .Navbar_menu {
    /* width: 55vw; */
  }
  .Navbarlogo {
    margin-top: 2%;
    width: 25vw;
  }
  .Navbar_main2 {
    margin-top: 12%;
  }
  .navbar_main {
    padding: 25px 3%;
    width: 94%;
  }
}
@media screen and (max-width: 300px) {
  .Navbar_menu {
    font-size: 12px;
  }
}
