.Footer {
  background: linear-gradient(272deg, #000 3.99%, rgba(0, 0, 0, 0.79) 103.42%);
  padding-top: 3%;
  padding-bottom: 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  align-items: center;
}
.Footer_main {
  width: 83vw;
  display: flex;
  justify-content: space-between;
}
.footer_svgs {
  width: 13vw;
  justify-content: space-between;
  display: flex;
}
.footer_svgs img {
  cursor: pointer;
}
.Footer_logo {
  cursor: pointer;
}
.footer_text {
  width: 25vw;
  color: #fff;
  /* text-align: justify; */
  font-size: 15px;
  font-weight: 400;
  /* font-family: Poppins; */
}

.footer_text_privacy {
  color: #fff;
  /* text-align: justify; */
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
}

.Footer_last_text {
  /* margin: 1em 0em; */
  color: #a9a9a9;
  display: flex;
}

.footer__bottom__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  margin: 2em 0em;
}
.footer__bottom__item {
  width: 25%;
}

.pricavy__text {
  text-align: end;
  /* width: 20% !important; */
  padding-right: 4em;
}

@media screen and (max-width: 1100px) {
  .Footer_main {
    align-items: center;
    flex-direction: column;
    width: auto;
  }
  .footer_svgs {
    margin-top: 4%;
    width: 48vw;
  }
  .footer_text {
    margin-top: 4%;
    width: 80vw;
    margin-bottom: 4%;
  }
}
@media screen and (max-width: 550px) {
  .footer_svgs {
    margin-top: 7%;
  }
  .footer_text {
    margin-top: 7%;
    margin-bottom: 7%;
  }
  .Footer {
    padding-top: 7%;
  }
  .footer__bottom__wrapper {
    flex-direction: column-reverse;
  }
  .footer__bottom__item,
  .pricavy__text {
    width: 100% !important;
  }
  .pricavy__text {
    text-align: center;
    padding: 0em;
  }
  .Footer_last_text {
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 2em;
  }
  .footer_text_privacy {
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 285px) {
  .Footer_last_text {
    font-size: 15px;
  }
}
