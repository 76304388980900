.main_home {
  /* background-image: url(../../Assest/home_image.png); */
  height: 100vh;
  /* background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover; */
  /* font-family: Poppins; */
  background: linear-gradient(180deg, #43ad90 0%, #20866a 100%);
}
.Find_member {
  width: 93vw;
  display: flex;
  justify-content: space-between;
  height: 100%;
  /* padding-top: 5%;
  padding-bottom: 5%; */
  align-items: center;
}
.find__member__left__img {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.find__member__left__img img {
  width: 100%;
}

.Find_member_detail {
  width: 40vw;
  align-items: center;
}
.Find_member_detail_text1 {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 124.5%;
  display: flex;
  justify-content: center;
  margin-bottom: 2.5em;
  margin-top: 6em;
  text-align: center;

  /* font-family: Poppins; */
}
.Find_member_detail_text2 {
  display: none;
}
.Find_member_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  /* background: rgba(217, 217, 217, 0.40);
box-shadow: 3px 4px 17px 0px rgba(0, 0, 0, 0.00);
backdrop-filter: blur(28.5px); */
  margin-top: 2%;
  padding: 1% 2%;
}
.Home_main_svg {
  display: none;
}
.Find_member_box_text {
  /* font-family: Plus Jakarta Sans; */
  margin-top: 1em;
  color: #1f1f1f;
  font-size: 18.706px;
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
  margin-bottom: 0.5em;
}
.Email_and_number {
  margin-top: 1%;
  display: flex;
  background-color: white;
  width: 30vw;
  padding: 0.6% 4%;
  border-radius: 30px;
  align-items: center;
  border: 1px solid transparent;
  height: 30px;
}
.home_email {
  padding: 2% 2% 2% 3.5%;
  margin-left: 2%;
  border: none;
  width: 20vw;
  border: none;
  outline: none;
}
.number_email {
  margin-left: 2%;
  border: none;
  width: 20vw;
  border: none;
  outline: none;
}
.flag-select-container {
  width: 100%;
}
.for_more_country {
  margin-left: 1%;
}
.check_box_with_text {
  display: flex;
  justify-content: none;
  align-items: flex-start;
  margin: 1.5em 0em;
  width: 30vw;
  color: black;
  font-weight: 600;
  /* text-align: justify; */
}
.consent__text {
  font-size: 10px;
}
.checkbox {
  margin-top: 1%;
  margin-right: 3%;
}
.waitlist {
  border-radius: 53px;
  background: #1f1f1f;
  padding: 2%;
  color: white;
  margin-bottom: 1%;
  cursor: pointer;
  font-weight: 600;
  /* font-family: Plus Jakarta Sans; */
  font-size: 22px;
  text-align: center;
  width: 87%;
  text-transform: uppercase;
}
.PhoneInput {
  display: flex !important;
  padding-bottom: 2%;
}
.PhoneInputCountry {
  display: flex;
  flex-direction: row-reverse;
  width: 3.2vw;
  position: relative !important;
  margin-bottom: 0.5em !important;
  justify-content: space-between !important;
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
}
.PhoneInputCountrySelect {
  width: 1.5vw !important;
  border: none !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  /* margin-left: 1em !important; */
  position: relative !important;
  z-index: 10 !important;
  padding-left: 4em !important;
  margin-top: 0.5em !important;
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
  /* padding-bottom: 0.1em; */
  height: 16.5px;
}
.PhoneInputCountryIcon {
  width: 2vw;
  margin-right: 1% !important;
  margin-top: 10% !important;
  position: absolute !important;
  left: 0 !important;
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
  /* margin-right: 1em !important; */
}
.PhoneInputInput {
  border: none !important;
  outline: none !important;
}
.email_svg {
  margin-top: 1.9%;
}
.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-right: 10px;
  cursor: pointer;
}
.custom-checkbox input {
  display: none;
}
.custom-checkbox .checkbox-border {
  width: 15px;
  height: 16px;
  border: 2px solid #000;
  position: absolute;
  left: -6px;
  top: -10px;
  background-color: transparent;
  text-align: center;
}
.custom-checkbox .checkmark {
  font-size: 18px;
  line-height: 20px;
  color: #000;
  opacity: 0;
}
.custom-checkbox input:checked + .checkbox-border .checkmark {
  opacity: 1;
}

.holistic__img {
  width: 123% !important;
}

.intl-tel-input input {
  width: 100%;
  border: none;
  padding: 0.5em 0em;
  outline: none;
}

.intl-tel-input.allow-dropdown .selected-flag {
  margin-left: -0.5em;
}

.flag-container {
  border: none;
  outline: none;
}

.country-list {
  max-width: 100%;
}

.intl-tel-input .country-list .country.highlight {
  width: fit-content;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-left: 44px !important;
}

@media screen and (max-width: 2500px) and (min-width: 1550px) {
  .PhoneInputCountrySelect {
    width: 0.9vw !important;
  }
}
@media screen and (max-width: 1100px) {
  .main_home {
    background-color: #3ca689;
    background-image: none;
  }
  .Home_main_svg {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    width: 60vw;
  }
  .find__member__left__img {
    display: none;
  }
  .Home_main_svg {
    margin-bottom: 10%;
    margin-top: 7%;
  }
  .Home_main_svg img {
    width: 100%;
  }
  .PhoneInputInput {
    width: 55vw;
  }
  .Find_member_detail {
    margin-top: 5%;
    width: 65vw;
    margin-top: 5%;
    width: 65vw;
    display: flex;
    flex-direction: column;
  }
  .Find_member_detail_text1 {
    font-size: 20px;
  }
  .Find_member_detail_text2 {
    display: flex;
    color: white;
  }
  .Find_member_box_text {
    font-size: 15px;
    margin-bottom: 5%;
  }
  .Email_and_number {
    margin-top: 3%;
    width: 65vw;
  }
  .check_box_with_text {
    width: 56vw;
    font-size: 10px;
  }
  .number_email {
    margin-left: 13%;
  }
  .home_email {
    margin-left: 5%;
    width: 55vw;
  }
  .Find_member {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .PhoneInputCountrySelect {
    width: 1.5vw !important;
  }
  .PhoneInputCountryIcon {
    width: 7vw;
  }
  .Find_member_box_text {
    font-weight: 800;
  }
  .waitlist {
    /* padding: 2% 14%; */
  }
  .PhoneInputCountry {
    width: 6.5vw;
    margin-bottom: 3%;
  }
}
@media screen and (max-width: 850px) {
  .PhoneInputCountrySelect {
    width: 2.5vw !important;
  }
  .holistic__img {
    width: 100% !important;
  }
}
@media screen and (max-width: 500px) {
  .PhoneInputCountrySelect {
    width: 5vw !important;
  }
  .PhoneInputCountry {
    width: 12.5vw;
    margin-bottom: 5%;
  }
  .main_home {
    /* height: auto; */
  }
  .Find_member_detail_text1 {
    margin-top: -3em;
    margin-bottom: 2em;
  }
  .Find_member_detail {
    width: 95vw;
  }
  .Home_main_svg {
    margin: 0;
    width: 100vw;
  }
  .check_box_with_text {
    width: 90%;
  }
  .Email_and_number {
    width: 90%;
  }
  .consent__text {
    font-size: 9px;
  }
  .waitlist {
    /* padding: 3% 18%; */
    width: 95%;
  }
  .shia_image_block_text_2 {
    text-align: center !important;
  }
  .shia_image_block_image {
    width: 100% !important;
  }
  .you_name_contact {
    margin-right: 0;
  }
  /* .you_name_contact_email {
    width: 90% !important;
  }
  .you_name_subject {
    width: 90% !important;
  } */
}
@media screen and (max-width: 285px) {
  .Email_and_number {
    margin-top: 3%;
    width: 72vw;
  }
}
